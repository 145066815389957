@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
[data-rsbs-overlay] {
  border-top-left-radius: 16px;
  border-top-left-radius: 16px;
  border-top-left-radius: var(--rsbs-overlay-rounded,16px);
  border-top-right-radius: 16px;
  border-top-right-radius: 16px;
  border-top-right-radius: var(--rsbs-overlay-rounded,16px);
  display: flex;
  background: #fff;
  background: #fff;
  background: var(--rsbs-bg,#fff);
  flex-direction: column;
  height: 0px;
  height: 0px;
  height: var(--rsbs-overlay-h,0px);
  transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  transform: translate3d(0, var(--rsbs-overlay-translate-y,0px), 0);
  will-change: height;
}

[data-rsbs-overlay]:focus {
  outline: none;
}

[data-rsbs-is-blocking='false'] [data-rsbs-overlay] {
  box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),
    0 -1px 0 rgba(38, 89, 115, 0.05);
}

[data-rsbs-overlay],
[data-rsbs-root]:after {
  max-width: auto;
  max-width: auto;
  max-width: var(--rsbs-max-w,auto);
  margin-left: env(safe-area-inset-left);
  margin-left: env(safe-area-inset-left);
  margin-left: var(--rsbs-ml,env(safe-area-inset-left));
  margin-right: env(safe-area-inset-right);
  margin-right: env(safe-area-inset-right);
  margin-right: var(--rsbs-mr,env(safe-area-inset-right));
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 3;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  touch-action: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

[data-rsbs-backdrop] {
  top: -60px;
  bottom: -60px;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--rsbs-backdrop-bg,rgba(0, 0, 0, 0.6));
  will-change: opacity;
  cursor: pointer;
  opacity: 1;
}

[data-rsbs-is-dismissable='false'] [data-rsbs-backdrop] {
  cursor: ns-resize;
}

[data-rsbs-root]:after {
  content: '';
  pointer-events: none;
  background: #fff;
  background: #fff;
  background: var(--rsbs-bg,#fff);
  height: 1px;
  transform-origin: bottom;
  transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  transform: scale3d(1, var(--rsbs-antigap-scale-y,0), 1);
  will-change: transform;
}

[data-rsbs-footer],
[data-rsbs-header] {
  flex-shrink: 0;
  cursor: ns-resize;
  padding: 16px;
}

[data-rsbs-header] {
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(1 * 0.125));
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(1 * 0.125));
  box-shadow: 0 1px 0
    rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125));
  z-index: 1;
  padding-top: calc(20px + env(safe-area-inset-top));
  padding-bottom: 8px;
}

[data-rsbs-header]:before {
  position: absolute;
  content: '';
  display: block;
  width: 36px;
  height: 4px;
  top: calc(8px + env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: hsla(0, 0%, 0%, 0.14);
  background-color: hsla(0, 0%, 0%, 0.14);
  background-color: var(--rsbs-handle-bg,hsla(0, 0%, 0%, 0.14));
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  [data-rsbs-header]:before {
    transform: translateX(-50%) scaleY(0.75);
  }
}

[data-rsbs-has-header='false'] [data-rsbs-header] {
  box-shadow: none;
  padding-top: calc(12px + env(safe-area-inset-top));
}

[data-rsbs-scroll] {
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  -moz-user-select: auto;
       user-select: auto;
  overflow: auto;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

[data-rsbs-scroll]:focus {
  outline: none;
}

[data-rsbs-has-footer='false'] [data-rsbs-content] {
  padding-bottom: env(safe-area-inset-bottom);
}

[data-rsbs-content] {
  /* The overflow hidden is to ensure any margin on child nodes are included when the resize observer is measuring the height */
  overflow: hidden;
}

[data-rsbs-footer] {
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(1 * 0.125)),
    0 2px 0 #fff;
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(1 * 0.125)),
    0 2px 0 #fff;
  box-shadow: 0 -1px 0 rgba(46, 59, 66, calc(var(--rsbs-content-opacity,1) * 0.125)),
    0 2px 0 var(--rsbs-bg,#fff);
  overflow: hidden;
  z-index: 1;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
}

[data-rsbs-is-dismissable='true'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-header] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-scroll] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-footer] > *, [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-footer] > * {
    opacity: 1;
    opacity: 1;
    opacity: var(--rsbs-content-opacity,1);
  }

[data-rsbs-is-dismissable='true'] [data-rsbs-backdrop], [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-backdrop], [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-backdrop] {
    opacity: 1;
    opacity: 1;
    opacity: var(--rsbs-backdrop-opacity,1);
  }

[data-rsbs-state='closed'],
[data-rsbs-state='closing'] {
  /* Allows interactions on the rest of the page before the close transition is finished */
  pointer-events: none;
}

._3pYe- {
  margin: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  padding: 0 min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  padding: 0 min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
}

._1veU8 {
  height: min(calc(34 / var(--ui-kit-design-width) * 100vw), calc( 34px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(34 / var(--ui-kit-design-width) * 100vw), calc( 34px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._PuJ1c {
  height: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._1OMLH {
  height: min(calc(48 / var(--ui-kit-design-width) * 100vw), calc( 48px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(48 / var(--ui-kit-design-width) * 100vw), calc( 48px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._XJaq- {
  border: none;
  background-color: #bc1433;
}

._32Ryc {
  border: none;
  background-color: #ffffff;
}

._6Lsbn {
  border: none;
  background-color: #bbc1ce;
}

._fhlqk {
  border: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) solid #bc1433;
  border: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) solid #bc1433;
  background-color: transparent;
}

._1qWoc {
  border: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) solid #ffffff;
  border: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) solid #ffffff;
  background-color: transparent;
}

._1wHfV {
  border: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) solid #bbc1ce;
  border: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) solid #bbc1ce;
  background-color: transparent;
}

._3hF-X {
  padding: 0;
  margin: 0;
}

._2vMYe {
  font-family: Inter, sans-serif;
  line-height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 700;
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._1imQH {
  font-family: Inter, sans-serif;
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 700;
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._38TCU {
  color: #bc1433;
}

._3JCPO {
  color: #ffffff;
}

._1_OV_ {
  color: #bbc1ce;
}

._3ITUm {
  display: flex;
  align-items: center;
  justify-content: center;
}

._2JDoG {
  margin-right: min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * 5000 / var(--ui-kit-design-width) ));
  margin-right: min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._2hfPT {
  margin-left: min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * 5000 / var(--ui-kit-design-width) ));
  margin-left: min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._3puNp {
  width: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._2GI5t {
  width: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}


._cDuLU {
  display: flex;
  align-items: center;
}
._cDuLU ._2pq2t {
  border-radius: 100%;
  display: inline-block;
  animation-fill-mode: both;
}
._cDuLU ._1V5ud {
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: 0.12s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: _1xoZ9;
}
._cDuLU ._3UDgp {
  margin: 0 min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  margin: 0 min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: 0.24s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: _1xoZ9;
}
._cDuLU ._3IkD- {
  animation-duration: 0.75s;
  animation-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  animation-delay: 0.36s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
  animation-name: _1xoZ9;
}

@keyframes _1xoZ9 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  45% {
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    transform: scale(1);
    opacity: 1;
  }
}

._24KnW {
  border: none;
  display: flex;
  padding: 0 min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * 5000 / var(--ui-kit-design-width) ));
  padding: 0 min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  cursor: pointer;
  min-height: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * 5000 / var(--ui-kit-design-width) ));
  min-height: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

._3DMz3 {
  display: flex;
  align-items: center;
  flex-direction: row;
}

._cGcZj {
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._x_zl9 {
  background-color: #f5f5f5;
}

._3wadd {
  background-color: #e6f7ef;
}

._1VPpw {
  background-color: #fff7e5;
}

._1ze3z {
  background-color: #fff3f3;
}

._oK6s8 {
  padding: 0;
  margin: 0 min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * 5000 / var(--ui-kit-design-width) )) 0 min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * 5000 / var(--ui-kit-design-width) ));
  margin: 0 min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) 0 min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-family: Inter, sans-serif;
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 500;
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._oGzs5 {
  color: #cc9200;
}

._3tmg_ {
  color: #f33055;
}

._3QkXi {
  color: #707070;
}

._2FQk4 {
  color: #12a25f;
}

._2mZrr {
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  margin-left: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  margin-left: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._2ZLVN {
  margin: 0;
  padding: 0;
  width: min(calc(32 / var(--ui-kit-design-width) * 100vw), calc( 32px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(32 / var(--ui-kit-design-width) * 100vw), calc( 32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  height: min(calc(32 / var(--ui-kit-design-width) * 100vw), calc( 32px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(32 / var(--ui-kit-design-width) * 100vw), calc( 32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

._2tD79 {
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._2WOf_ {
  border: none;
  display: flex;
  cursor: pointer;
  min-height: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * 5000 / var(--ui-kit-design-width) ));
  min-height: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  overflow: hidden;
  padding: 0 0 0 min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * 5000 / var(--ui-kit-design-width) ));
  padding: 0 0 0 min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  align-items: center;
  flex-direction: row;
  background-color: #12a25f;
  justify-content: space-between;
}

._Th0LL {
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._3jOaV {
  padding: 0;
  color: #ffffff;
  margin: 0 min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * 5000 / var(--ui-kit-design-width) ));
  margin: 0 min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-family: Inter, sans-serif;
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 700;
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._2g_Fa {
  color: #ffffff;
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  margin-left: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  margin-left: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._3G2m5 {
  margin: 0;
  height: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  border: none;
  display: flex;
  padding: 0 min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  padding: 0 min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  cursor: pointer;
  align-items: center;
  background-color: #0e824c;
}

._y9_Sb {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: Inter, sans-serif;
  line-height: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 700;
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._9B1IW {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.8) !important;
  --rsbs-bg: #ffffff;
  --rsbs-handle-bg: #bbc1ce4d;
  --rsbs-max-w: var(--hui-bottomsheet-max-width, auto);
  --rsbs-ml: auto;
  --rsbs-mr: auto;
  --rsbs-overlay-rounded: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc(
    20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width)
  )) !important;
}
._9B1IW [data-rsbs-header]:before {
  background-color: #858c9b;
  opacity: 0.3;
}
._9B1IW [data-rsbs-backdrop] {
  pointer-events: auto;
}
._9B1IW._1LQJ6 [data-rsbs-header] {
  display: none;
}
._3TBrM {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
  overflow: hidden;
}
._3TBrM._2UvtF {
  position: absolute;
}
._3TBrM._36pTF {
  pointer-events: auto;
}
._3TBrM._36pTF._1D3qi {
  background-color: rgba(0, 0, 0, 0.8);
}
._3TBrM ._1ewLV {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * 5000 / var(--ui-kit-design-width) ));
  padding: 0 min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
._3TBrM ._1ewLV ._3u99Z {
  pointer-events: auto;
  width: 100%;
  min-height: min(calc(50 / var(--ui-kit-design-width) * 100vw), calc( 50px * 5000 / var(--ui-kit-design-width) ));
  min-height: min(calc(50 / var(--ui-kit-design-width) * 100vw), calc( 50px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  background-color: #ffffff;
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  padding: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * 5000 / var(--ui-kit-design-width) ));
  padding: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  box-sizing: border-box;
  overflow: auto;
}
._3TBrM ._1ewLV ._2VodM {
  pointer-events: auto;
  margin: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) )) auto 0;
  margin: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) auto 0;
  width: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  height: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  border-radius: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  background-color: #ffffff;
  box-shadow: 0px min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * 5000 / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.05);
  box-shadow: 0px min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #303030;
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._3TBrM ._1ewLV._1QGm1 {
  opacity: 0;
}
._3TBrM ._1ewLV._1QGm1._oD3eX {
  opacity: 1;
}
._3TBrM ._1ewLV._2HW8m {
  transform: translateY(100%);
}
._3TBrM ._1ewLV._2HW8m._oD3eX {
  transform: translateY(0);
}
._3TBrM ._1ewLV._1wB-X {
  transform: translateY(-100%);
}
._3TBrM ._1ewLV._1wB-X._oD3eX {
  transform: translateY(0);
}
._3TBrM ._1ewLV._TFN7_ {
  transform: translateX(100%);
}
._3TBrM ._1ewLV._TFN7_._oD3eX {
  transform: translateX(0);
}
._3TBrM ._1ewLV._1Pk7T {
  transform: translateX(-100%);
}
._3TBrM ._1ewLV._1Pk7T._oD3eX {
  transform: translateX(0);
}
._3TBrM ._1ewLV._3aD9L {
  height: 100%;
  padding: 0;
}
._3TBrM ._1ewLV._3aD9L._2HW8m {
  flex-direction: column-reverse;
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._2HW8m ._2VodM {
  margin: 0 auto min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  margin: 0 auto min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._3TBrM ._1ewLV._3aD9L._1wB-X {
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._TFN7_ {
  flex-direction: row-reverse;
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._TFN7_ ._2VodM {
  margin: auto min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) )) auto 0;
  margin: auto min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) auto 0;
}
._3TBrM ._1ewLV._3aD9L._1Pk7T {
  flex-direction: row;
  justify-content: flex-start;
}
._3TBrM ._1ewLV._3aD9L._1Pk7T ._2VodM {
  margin: auto 0 auto min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  margin: auto 0 auto min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._3TBrM ._1ewLV._3aD9L ._3u99Z {
  width: 100%;
  height: 100%;
  border-radius: 0;
}
._2odNE ._2iyT2 {
  display: flex;
  padding-top: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  padding-top: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._2odNE ._2IuEd {
  flex-direction: column-reverse;
}
._2odNE ._2IuEd ._2NT9z {
  margin-top: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  margin-top: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._2odNE ._1E_mp {
  flex-direction: row;
}
._2odNE ._1E_mp ._10CHD {
  flex: 1 1;
}
._2odNE ._1E_mp ._2NT9z {
  margin-right: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  margin-right: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}

._OdOum {
  display: flex;
  align-items: center;
}
._OdOum ._Btvh8 {
  touch-action: pan-x;
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
       user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
._OdOum ._Btvh8 ._2ScBH {
  width: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(40 / var(--ui-kit-design-width) * 100vw), calc( 40px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  background: #bbc1ce;
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * 5000 / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  border-radius: min(calc(32 / var(--ui-kit-design-width) * 100vw), calc( 32px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(32 / var(--ui-kit-design-width) * 100vw), calc( 32px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  transition: all 0.2s ease;
  cursor: pointer;
}
._OdOum ._Btvh8 ._U6vpT {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * 5000 / var(--ui-kit-design-width) ));
  top: min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  left: min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * 5000 / var(--ui-kit-design-width) ));
  left: min(calc(2 / var(--ui-kit-design-width) * 100vw), calc( 2px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  width: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  height: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(20 / var(--ui-kit-design-width) * 100vw), calc( 20px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  border-radius: 50%;
  background-color: #fafafa;
  box-sizing: border-box;
  transition: all 0.25s ease;
  cursor: pointer;
}
._OdOum ._Btvh8 ._mUiFQ {
  border: 0;
  clip: rect(0 0 0 0);
  height: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) ));
  height: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  margin: max(calc(-1 / var(--ui-kit-design-width) * 100vw), calc( -1px * 5000 / var(--ui-kit-design-width) ));
  margin: max(calc(-1 / var(--ui-kit-design-width) * 100vw), calc( -1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) ));
  width: min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  cursor: pointer;
}
._OdOum ._2x8p2 {
  margin-left: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  margin-left: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  cursor: default;
  font-family: Inter, sans-serif;
  line-height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(24 / var(--ui-kit-design-width) * 100vw), calc( 24px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 700;
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(16 / var(--ui-kit-design-width) * 100vw), calc( 16px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._OdOum ._2GgaG ._2ScBH {
  background: #12a25f;
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * 5000 / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
}
._OdOum ._2GgaG ._U6vpT {
  left: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  left: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._OdOum ._2IlJR ._2ScBH {
  cursor: not-allowed;
  background: #bbc1ce;
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * 5000 / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}
._OdOum ._2IlJR._2GgaG ._2ScBH {
  cursor: not-allowed;
  background: #a0dabf;
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * 5000 / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px min(calc(4 / var(--ui-kit-design-width) * 100vw), calc( 4px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.1);
  opacity: 0.8;
}
._OdOum ._2IlJR ._U6vpT {
  cursor: not-allowed;
}

._3N02T {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * 5000 / var(--ui-kit-design-width) )) min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * 5000 / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.05);
  box-shadow: 0px min(calc(1 / var(--ui-kit-design-width) * 100vw), calc( 1px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(10 / var(--ui-kit-design-width) * 100vw), calc( 10px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) rgba(0, 0, 0, 0.05);
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  border-radius: min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  opacity: 0;
}
._3N02T ._2fLRW {
  display: flex;
  flex-basis: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * 5000 / var(--ui-kit-design-width) ));
  flex-basis: min(calc(42 / var(--ui-kit-design-width) * 100vw), calc( 42px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  align-items: center;
  justify-content: center;
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  color: #ffffff;
}
._3N02T ._10eLs {
  background-color: #12a25f;
}
._3N02T ._3Dt90 {
  background-color: #f33055;
}
._3N02T ._2zg4J {
  background-color: #fab300;
}
._3N02T ._1pXiX {
  display: flex;
  align-items: center;
  flex: 1 1;
  margin: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) )) min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * 5000 / var(--ui-kit-design-width) ));
  margin: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) )) min(calc(8 / var(--ui-kit-design-width) * 100vw), calc( 8px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-family: Inter, sans-serif;
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * 5000 / var(--ui-kit-design-width) ));
  line-height: min(calc(18 / var(--ui-kit-design-width) * 100vw), calc( 18px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  font-weight: 500;
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._3N02T ._1GFn6 {
  color: #12a25f;
}
._3N02T ._2lKqr {
  color: #f33055;
}
._3N02T ._2w96P {
  color: #fab300;
}
._3N02T ._2WB86 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * 5000 / var(--ui-kit-design-width) ));
  font-size: min(calc(14 / var(--ui-kit-design-width) * 100vw), calc( 14px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
  color: #858C9B;
  margin-right: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * 5000 / var(--ui-kit-design-width) ));
  margin-right: min(calc(12 / var(--ui-kit-design-width) * 100vw), calc( 12px * var(--ui-kit-scale-max-width, 5000) / var(--ui-kit-design-width) ));
}
._2Pqjg {
  animation: _M3BFZ 0.4s;
  transform: translateY(0);
  opacity: 1;
}
._La_pC {
  animation: _1gEel 0.4s;
}

@keyframes _M3BFZ {
  0% {
    transform: translateY(-200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes _1gEel {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
